$color_main: #707070;
$color_blue: #64c2c8;
$color_yellow: black;
$color_white: #ffffff;

$font_BC: "Arial", sans-serif;

$c_dark-blue: #0e1127;
$c_dark-gray: #343434;
$c_light-gray: #dce1e4;

body {
    position: relative;
}

.popup-fon {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 8998;
}

.popup {
    display: none;

    position: fixed;
    z-index: 9999;

    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);

    max-width: 1000px;
    width: perc(1000);

    box-sizing: border-box;
    border-radius: 25px;
    padding: 40px ac(50px, 20px);
    background: var(--white);
    overflow-x: hidden;

    transition: all 300ms ease-out;

    &-form {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        justify-items: center;
        grid-gap: 30px;
    }

    &-wrap {
        width: 100%;
    }

    .pc-cv-field, .popup-apply__bottom {
        grid-column: -1 / 1;
    }

    .popup-apply__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .close-popup {
        position: absolute;
        right: ac(50px, 20px);
        top: 40px;
        font-size: 25px;
        color: #4F4F4D;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        .close-icon {
            &::before {
                content: '\2573';
            }
        }

        &:hover {
            transform: rotate(120deg);
        }
    }

    .title-popup {
        margin-bottom: 35px;
        line-height: 130%;
    }

    .pf-flex {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media only screen and (max-width: 720px) {
            display: block;
        }

        .pf-column {
            width: 332px;
            margin-left: 38px;

            @media only screen and (max-width: 720px) {
                margin-left: 0;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .pc-label, .checkbox {
        margin-bottom: 18px;
    }

    @media only screen and (max-width: 769px) {
        width: 95%;

        .popup-form {
            grid-template-columns: 1fr;
        }
    }

    @media only screen and (max-width: 551px) {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.popup-open {
    .popup {
        display: block;
    }

    .popup-fon {
        display: block;
    }
}


.pf-row {
    margin-bottom: 18px;

    .pf-label {
        display: block;
        margin-bottom: 10px;

        font-size: 24px;
        line-height: 130%;
        color: $color_main;
        font-weight: normal;
    }

    .pf-text-field {
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        padding: 0 17px;
        background: $color_white;
        border: 1px solid #8b8b8b;

        font-size: 20px;
        line-height: 130%;
        color: $color_main;

        &::-moz-placeholder {
            opacity: 1;
            color: #d3d3d3;
        }
        &::-webkit-input-placeholder {
            opacity: 1;
            color: #d3d3d3;
        }
        &:focus::-moz-placeholder {
            opacity: 0;
        }
        &:focus::-webkit-input-placeholder {
            opacity: 0;
        }
    }
}

.checkBox {
    display: block;
    margin: 18px 0 42px 0;
    position: relative;

    input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }

    input:checked + .check-title:before {
        background: $color_blue;
    }

    .check-title {
        display: block;
        position: relative;
        padding-left: 40px;

        font-size: 20px;
        line-height: 130%;
        color: $color_main;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 1px;
            box-sizing: border-box;
            width: 25px;
            height: 25px;
            border: 1px solid $color_main;
            background: $color_white;
            transition: all 300ms ease-out;
        }
    }
}

.popup_btn,
a.popup_btn,
button.popup_btn {
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    text-transform: uppercase;
    display: block;
    background: none;
    color: $color_blue;
    border: $color_blue 1px solid;
    padding: 15px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 20px;
    cursor: pointer;

    &:hover {
        background: $color_blue;
        color: $color_white;
    }
}

/*-------------------------*/

.popup {
    &.popup_height_full {
        top: 0;
        bottom: 0;
        width: 756px;
        height: auto;
        margin-top: 0;
        padding: 20px 40px;
        justify-content: flex-start;
        transform: translateY(0);

        .close-popup {
            right: 40px;
            top: 18px;
        }
    }

    .title-popup {
        font-size: 24px;
        font-weight: 600;
        color: var(--dark-blue);
        padding-right: 50px;
    }

    .close-popup {
        right: 40px;
        top: 38px;
        font-size: 25px;
        color: #4f4f4d;
    }
}

.popup_body {
    box-sizing: border-box;
    width: 100%;
}

.file_img_block {
    width: 100%;
    max-width: 502px;
    margin-bottom: 40px;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    input {
        position: absolute;
        left: -150px;
        top: 0;
        width: calc(100% + 150px);
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }

    .file_img_btn {
        background: #f0eef0;
        border-radius: 4px;
        padding: 0 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        color: #272727;
        font-size: 15px;
        cursor: pointer;

        span {
            margin-right: 12px;
            cursor: pointer;
        }
    }
}

.img_grid_block {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 22px;

    & > div {
        position: relative;

        &:hover {
            .igb-pic:before {
                opacity: 1;
            }

            .img_del {
                opacity: 1;
            }
        }

        .igb-pic {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            padding-top: 70%;
            cursor: pointer;
            border-radius: 4px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                border: 2px solid #fe6b00;
                border-radius: 4px;
                transition: all 300ms linear;
                box-sizing: border-box;
                opacity: 0;
            }

            img {
                width: 100%;
                border-radius: 2px;
                cursor: pointer;
            }
        }

        .img_del {
            position: absolute;
            top: -5px;
            right: -5px;
            font-size: 16px;
            color: #272727;
            line-height: 1;
            cursor: pointer;
            transition: all 300ms linear;
            background-color: white;
            border-radius: 50%;
            opacity: 0;
            z-index: 2;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .popup {
        &.popup_height_full {
            width: 100%;
        }
    }

    .popup-center {
        width: 95%;
        min-height: auto;
        height: 90%;
        max-height: none;
        padding: 45px;

        .title-popup {
            margin-bottom: 30px;
        }

        .checkBox {
            margin: 0;
        }
    }

    .pc {
        &-inner {
            grid-gap: 20px;
        }

        &-field {
            input {
                padding: 12px;
            }
        }

        &-btn {
            padding: 12px;
        }
    }

    .custom-file-upload {
        padding: 15px;
    }
}

@media only screen and (max-width: 720px) {
    .pc {
        &-captcha {
            transform: scale(0.8);
            transform-origin: 0;

            & > div {
                width: 100%;
                height: 110px;
            }

            .g-recaptcha {
                width: 100%;
                height: 110px;

                & > div {
                    width: 100% !important;
                    height: 100% !important;
                }
            }

            iframe {
                width: 304px;
                height: 100%;
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .popup-center {
        width: 95%;
        height: 80%;
        padding: 20px 15px;

        .title-popup {
            margin-bottom: 40px;
            font-size: 18px;
        }

        .checkBox {
            margin: 0;
            grid-column: 1;

            .check-title {
                font-size: 15px;
            }
        }

        .close-popup {
            font-size: 20px;
            top: 20px;
            right: 15px;
        }
    }

    .pc {
        &-inner {
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }

        &-field {
            input {
                padding: 11px;
            }

            .pc-label {
                margin-bottom: 7px;
            }
        }

        &-label {
            font-size: 15px;
        }

        &-cv-field {
            grid-column: 1;
            margin-top: 10px;
        }

        &-btn {
            padding: 15px;
            margin-top: 20px;
        }

        &-captcha {
            grid-column: 1;
            transform-origin: center;

            .g-recaptcha {
                & > div {
                    & > div {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .custom-file-upload {
        padding: 19px;
    }
}

@media only screen and (max-width: 420px) {
    .popup {
        &.popup_height_full {
            padding: 40px 20px;
        }
    }

    .img_grid_block {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 15px;
    }
}

@media only screen and (max-width: 360px) {
    .pc {
        &-captcha {
            transform: scale(0.7);
            overflow: hidden;

            & > div {
                height: 110px;
            }

            .g-recaptcha {
                height: 110px;
            }

            iframe {
                width: 300px;
            }
        }
    }
}

@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap')";

@font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.eot?fvfdh9");
    src: url("../fonts/icomoon.eot?fvfdh9#iefix") format("embedded-opentype"),
        url("../fonts/icomoon.woff2?fvfdh9") format("woff2"),
        url("../fonts/icomoon.ttf?fvfdh9") format("truetype"),
        url("../fonts/icomoon.woff?fvfdh9") format("woff"),
        url("../fonts/icomoon.svg?fvfdh9#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
    content: "\e900";
}
.icon-facebook:before {
    content: "\e924";
}
.icon-linkedin:before {
    content: "\e925";
}
.icon-mail:before {
    content: "\e903";
}
.icon-tel:before {
    content: "\e906";
}

:root {
    --white: #ffffff;
    --dark-blue: #030e28;
    --blue: #2650bc;
    --light-blue: #00a2ff;
    --purple: #ebf0ff;
    --grey: #bababa;

    --font-main: "Comfortaa", sans-serif;
    --font-secondary: "Montserrat", sans-serif;

    /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
    --mobile-menu-start-point: 1025;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1024px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1025px);

/* Keyframes */

@keyframes rotate {
    0% {
        transform: rotateY(-360deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

@keyframes focus-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes wave-btn {
    0% {
        transform: rotate(0deg) scale(1);
    }

    50% {
        transform: rotate(180deg) scale(0.975);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

@keyframes wave-section {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1440px;
    }
}

@keyframes swell {
    0%,
    100% {
        transform: translate3d(0, -25px, 0);
    }
    50% {
        transform: translate3d(0, 5px, 0);
    }
}
